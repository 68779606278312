import React from 'react';
import { Stack, Text, Link,  FontWeights, IStackTokens, IStackStyles, ITextStyles } from '@fluentui/react';
import {Separator } from '@fluentui/react/lib/Separator';
import { List } from '@fluentui/react/lib/List';
import { useConst } from '@fluentui/react-hooks';
import logo from './logo.svg';
import './App.css';

const boldStyle: Partial<ITextStyles> = { root: { fontWeight: FontWeights.semibold } };
const stackTokens: IStackTokens = { childrenGap: 15 };
const stackStyles: Partial<IStackStyles> = {
  root: {
    width: '960px',
    margin: '0 auto',
    textAlign: 'center',
    color: '#605e5c',
  },
};

const layoutStyles: Partial<IStackStyles> = {
  root: {
    textAlign: 'left'
  }
};

export const App: React.FunctionComponent = () => {
  return (
    <Stack verticalFill styles={layoutStyles} tokens={stackTokens}>
      <Stack.Item>
        <div style={{height: '50px', paddingTop: '12px', paddingLeft: '8px'}}>
        <Text variant="xxLarge" styles={boldStyle}>NewWay App Home</Text>
        </div>
        <Separator />
      </Stack.Item>
      <Stack.Item grow={2}>
        <Stack styles={stackStyles}>
          <Text variant="large">小应用列表</Text>
          <Stack horizontal tokens={stackTokens} horizontalAlign="center">
            <Link href="https://blog.vikazhou.com/">Blog</Link>
            <Link href="http://app.vikazhou.com/notes">Notes</Link>
            <Link href="https://blog.vikazhou.com/hax/#/">Hax</Link>
          </Stack>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <div style={{padding: '20px 0px', textAlign:'center'}}>
        &copy;Vikazhou Newbie 2015-2022 <a href="https://beian.miit.gov.cn/">粤ICP备15096900号-2</a>
        </div>
      </Stack.Item>
    </Stack>
  );
};
